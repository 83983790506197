<template>
	<a-modal v-model="show" title="维护记录" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位" @select="selecttion">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="请选择车牌号" label-position="top" prop="carId">
						<select-car @input="e => (formData.carId = e[0])"></select-car>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="维护费用" label-position="top" prop="money">
						<a-input v-model="formData.money" type="number" placeholder="请输入维护费用"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="维护原因" label-position="top" prop="cause">
						<a-input v-model="formData.cause" placeholder="请输入维护原因"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="状态" label-position="top" prop="type">
						<a-select v-model="formData.type" placeholder="请选择车辆状态">
							<a-select-option :value="maintain" v-for="(maintain, index) in maintainList" :key="index">{{ maintain }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="维护日期" label-position="top" prop="date">
						<a-date-picker v-model="formData.date" show-time type="date" placeholder="请输入维护日期" />
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import selectCar from './selectCar.vue';
export default {
	components: { selectCar },
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			CarList: [],
			organId: apiUtil.getOrganId(),
			maintainList: ['维修', '加油', '洗车', '年检', '其他'],
			formData: {
				carId: null,
				type: '',
				cause: '',
				date: '',
				organId: null,
				money: ''
			},
			formRule: {
				carId: [{ required: true, message: '请选择车牌号' }],
				cause: [{ required: true, message: '请输入维护原因' }],
				date: [{ required: true, message: '请选择维护日期' }],
				type: [{ required: true, message: '请选择状态' }],
				money: [{ required: true, message: '请输入维护费用' }]
			}
		};
	},
	mounted() {},

	methods: {
		...apiUtil,
		_show(form) {
			this.getCarList();
			this.show = true;
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
				} else {
					this.formData.carId = null;
					this.formData.type = '';
					this.formData.cause = '';
					this.formData.organId = '';
					this.formData.date = '';
					this.formData.money = '';
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					request.post('/platform/car/maintain/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.show = false;
								this.$emit('success');
							});
						}
					});
				}
			});
		},

		selecttion(e) {
			// console.log('选择', e);
			this.formData.carId = '';
			this.getCarList(e);
		},
		getCarList(id = this.organId) {
			this.http
				.get('/platform/car/list', { organId: id })
				.then(ret => {
					this.CarList = ret.data.records;
				})
				.catch(err => {});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
</style>
